import "core-js/modules/es.number.constructor.js";
import useBaseUtils from "~/functions/useBaseUtils.js";
import dayjs from "~/utils/tools/dayjs";
import { getLogo, isValidLogo } from "~/utils/tools/logos";
export default {
  __name: 'ModelHubCard',
  props: {
    companyName: {
      type: String,
      required: true
    },
    cid: {
      type: [String, Number],
      required: true
    },
    tid: {
      type: [String, Number],
      "default": ""
    },
    tickersymbol: {
      type: String,
      required: true
    },
    hasActiveItems: {
      type: Boolean,
      "default": false
    },
    amountOfModels: {
      type: Number,
      required: true
    },
    lastModified: {
      type: String,
      required: true
    },
    createdAt: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      "default": false
    }
  },
  emits: ["delete"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      I18nFn = _useBaseUtils.I18nFn;
    var cardOptionsToggle = ref(false);
    var onDelete = function onDelete(dialogRef) {
      emit("delete", dialogRef);
    };
    return {
      __sfc: true,
      emit: emit,
      darkActive: darkActive,
      I18nFn: I18nFn,
      cardOptionsToggle: cardOptionsToggle,
      onDelete: onDelete,
      dayjs: dayjs,
      getLogo: getLogo,
      isValidLogo: isValidLogo
    };
  }
};